import { FC } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { useOpenCustomerMessageForm } from 'components/customer-message-form'

const FooterMobile: FC = () => {
  const openCustomerMessageForm = useOpenCustomerMessageForm()

  return (
    <StyledWrapper>
      <div className="flex-box">
        <Link href="/terms">
          <a>이용약관</a>
        </Link>
        <Link href="/privacy">
          <a>개인정보처리방침</a>
        </Link>
        <p onClick={openCustomerMessageForm}>고객문의</p>
      </div>
      <div className="copyright">
        Copyright © <b>Linkareer Inc</b>. All Rights Reserved.
      </div>
    </StyledWrapper>
  )
}

export default FooterMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
  background-color: #f4f4f4;
  .flex-box {
    display: flex;
    justify-content: center;
    gap: 40px;
    a,
    p {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .copyright {
    font-size: 12px;
  }
`
