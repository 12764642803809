import { FC, useState } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { ExpandMoreBold } from 'svgIcons'
import {
  ACTIVITIES_TAB,
  MOBILE_MENU_ITEMS,
  MOBILE_SECOND_DEPTH_MENU_ITEMS,
  NavBarBadge,
  TNavBar,
} from 'app.layout/constant/pageLayout.type'
import NewBadge from 'app.layout/desktop/NavBar/components/NewBadge'

const NavBarMobile: FC<TNavBar> = ({ activeKey, isSticky = false }) => {
  const [expandMenuVisible, setExpandMenuVisible] = useState(false)

  return (
    <StyledWrapper isSticky={isSticky}>
      <div className="menu-list">
        {MOBILE_MENU_ITEMS.map((menuItem) => (
          <Link key={menuItem.label} href={menuItem.href}>
            <a
              className="menu-item"
              data-active={menuItem.active === activeKey}
              data-badge={menuItem.badge === NavBarBadge.NEW}
            >
              {menuItem.label}
              <div className="active-line" />
              {menuItem.badge === NavBarBadge.NEW && <NewBadge />}
            </a>
          </Link>
        ))}
        <div
          className="expand-more-btn"
          onClick={() => setExpandMenuVisible((prev) => !prev)}
        >
          <ExpandMoreBold />
        </div>
      </div>
      {expandMenuVisible && (
        <div className="expand-wrapper">
          <div className="expand-menu-list">
            {MOBILE_MENU_ITEMS.map((menuItem) => (
              <Link key={menuItem.label} href={menuItem.href}>
                <a
                  className="expand-menu-item"
                  data-active={menuItem.active === activeKey}
                >
                  {menuItem.label}
                </a>
              </Link>
            ))}
            <Link href="/nav">
              <a className="expand-menu-item">더보기 +</a>
            </Link>
          </div>
        </div>
      )}
      {ACTIVITIES_TAB.includes(activeKey) && (
        <div className="second-depth-nav">
          {MOBILE_SECOND_DEPTH_MENU_ITEMS.map((menuItem) => (
            <Link key={menuItem.label} href={menuItem.href}>
              <a
                className="second-depth-item"
                data-active={menuItem.active === activeKey}
              >
                {menuItem.active === 8 && !expandMenuVisible && <NewBadge />}
                {menuItem.label}
              </a>
            </Link>
          ))}
        </div>
      )}
    </StyledWrapper>
  )
}

export default NavBarMobile

type TStyleProps = {
  isSticky: boolean
}

const StyledWrapper = styled.div<TStyleProps>`
  ${({ theme, isSticky }) => css`
    position: ${isSticky ? 'sticky' : 'relative'};
    top: 0;
    z-index: 3;
    padding-right: 20px;
    background-color: ${theme.background.bg_8};

    .menu-list {
      display: flex;
      border-bottom: 1px solid ${theme.line.line_3};
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 10px;
      white-space: nowrap;
      -ms-overflow-style: none;

      ::-webkit-scrollbar {
        display: none;
      }

      &:hover {
        background-color: transparent;
      }

      .menu-item {
        height: 50px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: -0.64px;

        .active-line {
          display: none;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: ${theme.color.primary_2};
        }

        &[data-active='true'] {
          color: ${theme.color.primary_2};

          .active-line {
            display: block;
          }
        }

        &[data-badge='true'] {
          padding-right: 20px;
        }
      }

      .expand-more-btn {
        position: absolute;
        top: 10px;
        right: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${theme.background.bg_8};
        border: 1px solid ${theme.line.line_3};

        svg {
          width: 10px;
        }
      }
    }

    .expand-wrapper {
      position: absolute;
      top: 51px;
      left: 0;
      right: 0;
      height: 100vh;
      background-color: rgba(10, 10, 10, 0.86);

      .expand-menu-list {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        gap: 10px;
        background-color: ${theme.background.bg_8};

        .expand-menu-item {
          font-size: 12px;
          padding: 7px 20px;
          background-color: ${theme.background.bg_1};
          border-radius: 20px;

          &[data-active='true'] {
            color: ${theme.background.bg_8};
            background-color: ${theme.color.primary_2};
          }
        }
      }
    }

    .second-depth-nav {
      height: 44px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      gap: 24px;
      border-bottom: 1px solid ${theme.line.line_3};

      .second-depth-item {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: -0.64px;
        flex-shrink: 0;

        &[data-active='true'] {
          color: ${theme.color.primary_2};
          font-weight: bold;
        }

        .new-badge {
          top: -3px;
          right: -9px;
        }
      }
    }
  `}
`
