import { FC } from 'react'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  NotificationScope,
  useGqlPageLayoutMobileQuery,
} from 'generated/graphql'
import UpsellAppModal from '@app.components/modals/UpsellAppModal/UpsellAppModal'
import useUpsellAppModal from '@app.modules/hooks/useUpsellAppModal'
import { TMobileRenderOptions } from 'app.layout/constant/pageLayout.type'
import AdvertiseBanner from 'components/AdvertiseBanner/screen/AdvertiseBanner'
import FooterMobile from './Footer/Footer.mobile'
import HeaderMobile from './Hearder/Header.mobile'
import NavBarMobile from './NavBar/NavBar.mobile'

gql`
  query gqlPageLayoutMobile($notificationType: NotificationScope) {
    currentUser {
      ...HeaderMobile_user
    }
  }
`

interface IFPageLayoutMobileProps {
  className?: string
  renderOptions: TMobileRenderOptions
  isMainLogoTitle?: boolean
}

const PageLayoutMobile: FC<IFPageLayoutMobileProps> = ({
  className,
  children,
  renderOptions,
  isMainLogoTitle,
}) => {
  const { Header, NavBar, Footer, BottomFixedAd } = renderOptions
  const { upsellAppModalIsOpen, handleUpsellAppModalClose } =
    useUpsellAppModal()

  const { data } = useGqlPageLayoutMobileQuery({
    variables: { notificationType: NotificationScope.NOTIFICATION },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  })

  return (
    <StyledWrapper className={className}>
      {Header && (
        <HeaderMobile
          isMainLogoTitle={isMainLogoTitle}
          currentUser={data?.currentUser}
          isSticky={Header.isSticky}
          searchBar={Header.searchBar}
        />
      )}
      {NavBar && (
        <NavBarMobile activeKey={NavBar.activeKey} isSticky={NavBar.isSticky} />
      )}
      <main className="main-container">{children}</main>
      {Footer && <FooterMobile />}
      {BottomFixedAd && (
        <AdvertiseBanner
          code="mobile-bottom-fixed-ad"
          className="mobile-bottom-fixed-ad"
          disableLoadingProgress
        />
      )}
      <UpsellAppModal
        isOpen={upsellAppModalIsOpen}
        onModalClose={handleUpsellAppModalClose}
      />
    </StyledWrapper>
  )
}

export default PageLayoutMobile

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  background-color: #fafafa;
  .main-container {
    flex-grow: 1;
  }
  .mobile-bottom-fixed-ad {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }

  & > div {
    .career-modal-content {
      top: unset;
      bottom: 64px;
      transform: translate(-50%, 0);
    }
  }
`
